@use "Vars";

.umaBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    min-width: 300px;
    color: Vars.$white-color;
    border: none;
    background: Vars.$uma-primary;
    height: 40px;
    cursor: pointer;
    &.green {
        background: Vars.$green-high;
        font-weight: 800;
    }
    &.secondary {
        background: Vars.$uma-secondary;
    }
    &.secondary-outline {
        background: none;
        border: 1px solid Vars.$uma-secondary;
        color: Vars.$uma-secondary;
    }
    &.primary-outline {
        background: none;
        border: 1px solid Vars.$uma-primary;
        color: Vars.$uma-primary;
    }
    &.upload {
      background-color: Vars.$white-color;
      border: 1px solid Vars.$uma-primary;
      box-shadow: Vars.$dark-shadow;
      margin: 20px auto;
      color: Vars.$uma-primary;
    }
}

.attachFile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  cursor: pointer;
  .attachFile__icon {
    font-size: 1.5rem;
    margin: 0 5px;
  }
  input {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
}