.divider {
	border-bottom: 0.3px solid #90a4ae;
}

.divider:last-child {
	border-bottom: none;
}

.point {
	background-color: #e54055;
	width: 8px;
	height: 8px;
	border-radius: 100%;
	position: absolute;
	left: 10px;
}
