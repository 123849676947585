@use "../global/Vars.scss";

.Profile {
  // Box
  padding: 12px 12px 0 12px;
  height: calc(100% - 76px);
  // Content
  position:relative;
  display: block;
  overflow-y: scroll;
}

.ProfileUser__updateAvatar {
  position: absolute;
  bottom: 2px;
  right: 1px;
  border-radius: 100%;
  background: white;
  border: 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ProfileUser__camera {
  &:hover {
    color: rgb(53, 98, 255);
  }
}

.ProfileUser {
  // box
  padding: 22px;

  // Content
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ProfileNotification {
  // Box
  width: 100%;
  margin-top: 8px;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 1px solid #bdbdbd;
  // Content
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  label {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: "description switch";
    span {
      grid-area: description;

      /* Typography/H3/Regular */
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;

      /* or 19px */

      /* ÜMA/primary/text/color */
      color: #323338;
    }
  }
}

.PushNotificationSwitch {
  grid-area: switch;
  justify-self: end;
  align-self: center;
}

.ProfileDependant,
.ProfileSubscription {
  // Box
  width: 100%;
  // Content
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.ProfileDependant__context,
.ProfileSubscription__context {
  width: 100%;
}

.ProfileDependant__title,
.ProfileSubscription__title {
  /* Typography/H3/Bold */
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;

  /* ÜMA/primary/text/color */
  color: #323338;
}
.DependantElementInset {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}

.DependantElement {
  background-color: theme('colors.secondary.100');
  margin: 6px 0;
  padding: 10px;
}

.SubscriptionElement {
  margin-top: 8px;
}

.DependantElement,
.SubscriptionElement {
  /* Box */
  width: 100%;


  &:hover {
    background: hsla(230, 6, 21, 0.1);
  }

  &:focus {
    outline: 2px solid var(--color-primary);
    background: hsla(230, 6, 21, 0.1);
  }

  &:focus-visible {
    outline: 2px solid var(--color-primary);
    background: hsla(230, 6, 21, 0.1);
  }

  /* Typography/Text1/Semibold */
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;

  /* ÜMA/primary/text/color */
  color: #323338;

  // Content
  display: flex;
  align-content: center;
  align-items: center;
}

.Profile__dataElement,
.Profile__editForm {
  position: relative;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #bdbdbd;
}

.Profile__sectionData {
  // Box
  width: 100%;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;

    /* ÜMA/primary/text/color */
    color: #323338;

    // box
    margin-bottom: 16px;
  }

  h2,
  label {
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 130%;

    /* ÜMA/primary/text/color */
    color: #000;

    // box
    margin-bottom: 10px;
  }

  p,
  input {
    /* Typography/H3/Bold */
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 120%;

    /* ÜMA/primary/text/color */
    color: #323338;

    // box
    margin-bottom: 4px;
  }

  input {
    border-style: none;
    background: #edf2f7;
    height: 48px;
    padding: 0 0 0 0;
  }

  label {
    position: absolute;
    font-size: 0.8rem;
    color: #137ded;
  }
}

.profile__downButton {
  // Box
  position: absolute;
  bottom: 16px;
  left: auto;
  right: auto;
  margin: 0 auto;
  width: calc(100% - 32px);

  //Content
  display: flex;
  left: 14px;
  flex-direction: column; 
  justify-content: center;
  align-content: center;
  align-items: center;
}

.Profile__linkTest {
  color: #137ded;
  border: none;
  border-radius: 12px;
  padding: 8px;
  cursor: pointer;
  transition: background 0.3s, border-color 0.3s;
  text-align: center;
  text-decoration: none;

  &:focus {
    outline: 2px solid var(--color-primary);
    background: hsla(230deg, 6%, 21%, 0.1);
  }
}

.LinkButton {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 12px 6px;
    box-sizing: border-box;
    border-radius: 8px;
    border: 1px none;
    &:focus, &:hover {
        background: Vars.$color-grey-5;
        outline: 0;
    }
    
    &:focus {
        border: 1px solid Vars.$color-primary
    }
}

.ProfilePhoto {
  position: absolute;
  right: 38px;
  bottom: -18px;
  border-radius: 100%;
  background: white;
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  filter: alpha(opacity=0);
  opacity: 0;
}

.inputFile {
  position: relative;
}

.iconFile{
  position: absolute;
  right: 38px;
  bottom: -18px;
  border-radius: 100%;
  background: theme('colors.secondary.100'); // Use tailwind global theme
  height: 44px;
  width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  border: theme('colors.background.patient') 2px solid; // Use tailwind global theme
}