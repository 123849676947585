@use '@/styles/global/Vars.scss';

@mixin text-focus {
	/* Typography/Subtext/Bold */
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	/* or 17px */
	color: #323338;
}

@mixin text-normal {
	/* Typography/Subtext/Bold */
	font-style: normal;
	font-weight: 700;
	font-size: 14px;
	line-height: 120%;
	/* or 17px */
	/* ÜMA/secondary/text/color */
	color: #6f6f6f;
}

.Specialist__content {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	gap: 10px;
	position: relative;
	height: calc(100% - 40px);
}

.Specialist__assignedAppointments {
	display: flex;
	flex-direction: column;
	padding: 16px;
	gap: 10px;
	position: relative;
	height: 100%;
}

.Select__specialist {
	/* Auto layout */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px;
	gap: 10px;
	position: relative;
}

.Skeleton__wraper {
	width: 100%;
}

.buttonBottom {
	position: fixed;
	place-self: center;
	bottom: 16px;
	padding: 0 16px;
	max-width: 560px;
	width: 95dvw;
}

@mixin Specialist__cardContainer($disabled: false) {
	width: 100%;
	height: 106px;
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	border: 1px solid #e0e0e0;
	box-shadow: 0px 4px 4px 0px #0000000d;
	border-radius: 8px;

	@if $disabled {
		opacity: 0.6;
	}
}

.textarea {
	padding: 3%;
	background: Vars.$white-color;
	border: 0.5px solid #959595;
	box-sizing: border-box;
	border-radius: 5px;
	height: 45vh;
	width: 100%;

	&:focus {
		border: 2px solid var(--color-primary);
	}

	&::placeholder {
		font-size: 1.1rem;
	}

	font-size: 1rem;
}

.confirmTurn__disclaimer {
	max-width: 350px;
	border: 2px solid Vars.$uma-primary;
	border-radius: 3px;
	padding: 16px 12px;
	margin-bottom: 16px;
}

.mercadopago-button {
	width: 100%;
	background: Vars.$uma-primary !important;
}

.doctorSearch {
	padding: 8px 25px;
	position: relative;
	width: 100%;
	box-sizing: border-box;
	border: 1px solid var(--color-primary);
	border-radius: var(--border-radius-lg);
}

.searchBarContainer {
	position: relative;
	width: 100%;
}


.Icon {
	position: absolute;
	top: 10px;
	left: 8px;
}

.positionContainer {
	display: block;
	width: 100%;
}

.specialistTimeCard {
	position: absolute;
	bottom: 0;
	width: 100%;
	padding: 0px 16px 16px 16px;
	box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
	max-height: 30%;
	background: white;
	overflow-y: scroll;
	overflow-x: hidden;
}

.pendingPrescriptions_cards {
	div:last-child {
		margin-bottom: 80px;;
	}
}
