.ProfileEditForm {
	padding: 16px;
	padding-bottom: 100px;
}
.Loader {
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.profile__downButton {
	// Box
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 16px;

	//Content
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;

	background-color: white;
	box-shadow: 0px -3px 6px rgba(0, 0, 0, 0.05);
}

@media screen and (min-width: 400px) {
	.profile__downButton {
		position: absolute;
	}
}
