  .question {
    font-size: 18px;
    font-weight: bold;
    list-style-type: initial;
    margin: 10px 0;
  }
  
  .answer {
    font-size: 16px;
  
    &::first-letter {
      text-transform: capitalize;
    }
  }