.showSpecialties__container{
    ul{
        margin-left: 64px;
        list-style: square;
        display: flex;
        flex-direction: column;
        li{
            list-style-type: disc;
            margin-bottom: 8px;
        }
    }

    button {
        margin: auto;
    }
}