@use "../index.scss";
@use "@/styles/global/Vars";

.reason__content {
    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    gap: 10px;
}

.reason__textarea {
    padding: 3%;
    background: Vars.$white-color;
    border: 0.5px solid #959595;
    box-sizing: border-box;
    border-radius: 5px;
    height: 45vh;
    width: 100%;
    &:focus {
		border: 2px solid var(--color-primary);
    }
    &::placeholder {
        font-size: 1.1rem;
    }
    font-size: 1rem;
}

.reason__buttonBottom {
    position: fixed;
    bottom: 16px;
    padding: 0 16px;
    max-width: 560px;
    width: 100%;
}