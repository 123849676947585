@use '../global/Vars.scss';

.selectService__container {
    .selectService__list {
        display: flex;
        flex-direction: column;
        align-items: center;

        .selectService__item {
            width: 90%;
            border: 1px solid rgba(0, 0, 0, 0.05);
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            display: flex;
            justify-content: space-between;
            padding: 16px 32px;

            p {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                color: Vars.$uma-primary-opacity;
            }
        }
    }
}