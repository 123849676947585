.CardLinkContainer{
    box-shadow: none!important;
    background-color: rgba(10, 145, 228, 0.1)!important;
    width: 100%!important;
    margin: 8px 0;
    .cardContainer {
        display: flex;
        width: 100%;
        height: 60px;
        justify-content: space-between;
        flex: 1;
        /* Hacer que el enlace ocupe todo el espacio disponible en el contenedor */
        padding: 8px 16px 8px 8px;
        align-content: center;
        align-items: center;
        box-shadow: none;
        color: rgba(10, 145, 228, 1);
        font-size: 14px;
        font-weight: 300;
        .cardLinkInfo {
            display: flex;
            width: 80%;
            gap: 20px;
        }
    }
}