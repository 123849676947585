.videoCall{
  border-radius: 14px;
  z-index: 40;
  position: absolute;
  top: 4.4rem;
  right: 1.5rem; 
  width: 7rem; 
  height: 8rem; 
  background-color: #e5e5e5;
  overflow: hidden;
}

.videoCallChat {
  z-index: 1000001;
  position: absolute;
  top: 0rem;
  right: 1rem; 
  width: 6rem; 
  height: 6rem; 
  overflow: hidden;
  background-color: #e5e5e5;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.videoCallChatDoc {
  z-index: 1000001;
  position: absolute;
  top: 0rem;
  right: 7rem; 
  width: 6rem; 
  height: 6rem; 
  overflow: hidden;
  background-color: #e5e5e5;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}