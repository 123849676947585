.Alert {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #FFFBDB;
    border: 1px solid #FFEF5F;
    padding: 16px;
    text-align: left;
    margin-bottom: 12px;
    border-radius: 8px;

    p {
        padding: 2px;
    }


}