.buttonIcon {
    font-family: inherit;
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
}

.content {
    border-radius: 16px;
    pointer-events: auto;
}

.fullWidth {
    width: 100%
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4); 
    z-index: 30;
}

.button {
    pointer-events: auto;
}

.text_centered {
    text-align: center;
}
