@use "/src/styles/index.scss";
@use "/src/styles/global/Vars";

.sharePrescriptionButton{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    gap: 8px;
    margin: 0 16px;
    cursor: pointer;
    background-color: transparent;
}
.iconSharePrescription{
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid Vars.$color-primary;
    border-radius: 50%;
}
.iconSharePrescriptionSelected{
    border: 2px solid Vars.$color-primary--pressed;
}